<template>
  <div>
    <!--    <div style="position: fixed; top: 0; left: 0; width: 400px; height: 500px; background: black; overflow: scroll">-->
    <!--      <pre>{{ profile.customer.permissions }}</pre>-->
    <!--    </div>-->
    <form @submit.prevent="onSubmit">
      <FormRow v-if="showCustomers">
        <FormSelect
          id="customers"
          :size="size"
          v-model="profile.customer"
          :options="orderStringByKey(getCustomers)"
          optionLabel="name"
          :placeholder="$t('placeholder.select_a_customer')"
        />
      </FormRow>
      <FormRow v-if="showRegions">
        <FormSelect
          id="region"
          :size="size"
          v-model="profile.region"
          :options="orderStringByKey(profile.customer.regions)"
          optionLabel="name"
          :placeholder="$t('placeholder.select_a_region')"
        />
      </FormRow>

      <ButtonUI
        type="submit"
        :size="size"
        :class="{ 'w-full justify-content-center': size === 'xl' }"
        :label="$t('button.continue')"
        :disabled="profile?.region?.id ? false : true"
      />
    </form>
  </div>
</template>
<script>
import { useAuthStore, useNavStore } from "@/stores";

import FormRow from "@/components/form/FormRow.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import { router, orderStringByKey } from "@/helpers";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

export default {
  setup() {
    const authStore = useAuthStore();
    const profile = ref({
      customer: [],
    });

    const tempCustomer = ref(null);

    const { getCustomers } = storeToRefs(authStore);

    if (!authStore.customerHasRegions()) {
      router.push({ name: "dashboard" });
    }

    if (authStore.loggedIn && authStore.hasCustomerProfile) {
      authStore.refreshAuthCustomers();
    }

    const showCustomers = computed(() => {
      return getCustomers.value.length > 1;
    });

    const showRegions = computed(() => {
      return getCustomers.value.length <= 1 || (getCustomers.value.length > 1 && profile.value.customer.id);
      // show regions if 1 or no customers OR show regions if 2 or more customers AND if user selected customer
    });

    const onSubmit = async () => {
      try {
        const newProfile = {
          customer: {
            id: profile.value.customer.id,
            name: profile.value.customer.name,
          },
          region: {
            id: profile.value.region.id,
            name: profile.value.region.name,
          },
          code: profile.value.customer.code,
          permissions: profile.value.customer.permissions,
        };
        await authStore.setCustomerProfile({ scenario: "change", profile: newProfile });
      } catch (error) {
        console.log(error);
      }
    };

    watch(getCustomers, (customers) => {
      if (customers.length <= 1) {
        profile.value = {
          customer: customers[0], // select first customer
        };
      }
    });

    watch(
      profile,
      (value) => {
        if (profile.value.customer.regions.length === 1) {
          profile.value.region = value.customer.regions[0];
        }
        if (value.customer.id !== tempCustomer.value) {
          profile.value.region = {};
        }
        tempCustomer.value = value.customer.id;
      },
      {
        deep: true,
      }
    );

    return { showCustomers, showRegions, getCustomers, profile, tempCustomer, onSubmit };
  },

  components: {
    FormRow,
    FormSelect,
    ButtonUI,
  },
  props: {
    size: {
      type: String,
      default: "xl",
    },
  },
  methods: {
    orderStringByKey(arr) {
      return orderStringByKey(arr, "name");
    },
  },
};
</script>
